<template>
  <div class="login-index">
    <router-view />
    <canvas ref="myCanvas" id="myCanvas"></canvas>
  </div>
</template>
<script>
export default {
  data() {
    return {
      circleArr: [],
      context: {},
      POINT: "",
      WIDTH: window.innerWidth,
      HEIGHT: window.innerHeight,
      canvas: ""
    };
  },
  mounted() {
    //定义画布宽高和生成点的个数
    this.WIDTH = window.innerWidth;
    this.HEIGHT = window.innerHeight;
    this.POINT = 35;

    this.canvas = document.getElementById("myCanvas");
    this.canvas.width = this.WIDTH;
    this.canvas.height = this.HEIGHT;
    this.context = this.canvas.getContext("2d");
    this.context.strokeStyle = "rgba(0,0,0,0.02)";
    this.context.strokeWidth = 1;
    this.context.fillStyle = "rgba(0,0,0,0.05)";
    this.circleArr = [];
    // this.drawCanvas();
    this.init();
    setInterval(() => {
      for (var i = 0; i < this.POINT; i++) {
        var cir = this.circleArr[i];
        cir.x += cir.moveX;
        cir.y += cir.moveY;
        if (cir.x > this.WIDTH) cir.x = 0;
        else if (cir.x < 0) cir.x = this.WIDTH;
        if (cir.y > this.HEIGHT) cir.y = 0;
        else if (cir.y < 0) cir.y = this.HEIGHT;
      }
      this.draw();
    }, 16);
  },
  methods: {
    // // 调用执行
    // drawCanvas() {
    //   this.init();
    //   setInterval(function() {
    //     for (var i = 0; i < this.POINT; i++) {
    //       var cir = this.circleArr[i];
    //       cir.x += cir.moveX;
    //       cir.y += cir.moveY;
    //       if (cir.x > this.WIDTH) cir.x = 0;
    //       else if (cir.x < 0) cir.x = this.WIDTH;
    //       if (cir.y > this.HEIGHT) cir.y = 0;
    //       else if (cir.y < 0) cir.y = this.HEIGHT;
    //     }
    //     // this.draw();
    //   }, 16);
    // },
    //线条：开始xy坐标，结束xy坐标，线条透明度
    Line(x, y, _x, _y, o) {
      (this.beginX = x),
        (this.beginY = y),
        (this.closeX = _x),
        (this.closeY = _y),
        (this.o = o);
    },
    //点：圆心xy坐标，半径，每帧移动xy的距离
    Circle(x, y, r, moveX, moveY) {
      (this.x = x),
        (this.y = y),
        (this.r = r),
        (this.moveX = moveX),
        (this.moveY = moveY);
    },
    //生成max和min之间的随机数
    num(max, _min) {
      var min = arguments[1] || 0;
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    // 绘制原点
    drawCricle(cxt, x, y, r, moveX, moveY) {
      var circle = new this.Circle(x, y, r, moveX, moveY);
      cxt.beginPath();
      cxt.arc(circle.x, circle.y, circle.r, 0, 2 * Math.PI);
      cxt.closePath();
      cxt.fill();
      return circle;
    },
    //绘制线条
    drawLine(cxt, x, y, _x, _y, o) {
      var line = new this.Line(x, y, _x, _y, o);
      cxt.beginPath();
      cxt.strokeStyle = "rgba(0,0,0," + o + ")";
      cxt.moveTo(line.beginX, line.beginY);
      cxt.lineTo(line.closeX, line.closeY);
      cxt.closePath();
      cxt.stroke();
    },
    //初始化生成原点
    init() {
      this.circleArr = [];
      for (var i = 0; i < this.POINT; i++) {
        this.circleArr.push(
          this.drawCricle(
            this.context,
            this.num(this.WIDTH),
            this.num(this.HEIGHT),
            this.num(15, 2),
            this.num(10, -10) / 40,
            this.num(10, -10) / 40
          )
        );
      }
      this.draw();
    },

    //每帧绘制
    draw() {
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      for (var i = 0; i < this.POINT; i++) {
        this.drawCricle(
          this.context,
          this.circleArr[i].x,
          this.circleArr[i].y,
          this.circleArr[i].r
        );
      }
      for (var i = 0; i < this.POINT; i++) {
        for (var j = 0; j < this.POINT; j++) {
          if (i + j < this.POINT) {
            var A = Math.abs(this.circleArr[i + j].x - this.circleArr[i].x),
              B = Math.abs(this.circleArr[i + j].y - this.circleArr[i].y);
            var lineLength = Math.sqrt(A * A + B * B);
            var C = (1 / lineLength) * 7 - 0.009;
            var lineOpacity = C > 0.03 ? 0.03 : C;
            if (lineOpacity > 0) {
              this.drawLine(
                this.context,
                this.circleArr[i].x,
                this.circleArr[i].y,
                this.circleArr[i + j].x,
                this.circleArr[i + j].y,
                lineOpacity
              );
            }
          }
        }
      }
    }
  }
};
</script>
<style scopedthis.>
.login-index {
  background-color: #f5f5f5;
  height: 100vh;
  overflow: hidden;
  text-align: center;
}
#myCanvas {
  width: 100%;
  height: 100%;
}
</style>
